  .opacity-0 {
    opacity: 0 !important;
  }

  .opacity-1 {
    opacity: 0.2 !important;
  }

  .opacity-2 {
    opacity: 0.4 !important;
  }

  .opacity-3 {
    opacity: 0.6 !important;
  }

  .opacity-4 {
    opacity: .8 !important;
  }

  .opacity-5 {
    opacity: 1 !important;
  }

  /* Maybe even support hover opacity shifts */

  .opacity-0h5 {
    opacity: 0 !important;
    transition: opacity .25s ease-in-out !important;
    -moz-transition: opacity .25s ease-in-out !important;
    -webkit-transition: opacity .25s ease-in-out !important;
  }

  .opacity-0h5:hover {
    opacity: 1 !important;
  }

  html {
    font-size: 0.85rem !important;
  }

  .no-border {
    border: none !important;
  }

  .no-background {
    background-color: transparent !important;
  }

  .bi-person-check {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10zm4.854-7.85a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z'/></svg>");
    background-repeat: no-repeat;
    height: 1.2em;
    width: 1.2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .bi-person-x {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10zm1.146-7.85a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z'/></svg>");
    background-repeat: no-repeat;
    height: 1.2em;
    width: 1.2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .bi-circle-plus {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/><path fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>");
    background-repeat: no-repeat;
    height: 1.2em;
    width: 1.2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .bi-trash {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z'/><path fill-rule='evenodd' d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z'/></svg>");
    background-repeat: no-repeat;
    height: 1.2em;
    width: 1.2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .bi-arrow-left {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z'/></svg>");
    background-repeat: no-repeat;
    height: 2em;
    width: 2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .bi-arrow-right {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/></svg>");
    background-repeat: no-repeat;
    height: 2em;
    width: 2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .chevron-bar-contract {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M3.646 13.854a.5.5 0 0 0 .708 0L8 10.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zm0-11.708a.5.5 0 0 1 .708 0L8 5.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708z'/></svg>");
    background-repeat: no-repeat;
    height: 1.2em;
    width: 1.2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .chevron-bar-expand {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z'/></svg>");
    background-repeat: no-repeat;
    height: 1.2em;
    width: 1.2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .printer {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z'/><path d='M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z'/></svg>");
    background-repeat: no-repeat;
    height: 1.2em;
    width: 1.2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .menu {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M0 1.5A1.5 1.5 0 0 1 1.5 0h2A1.5 1.5 0 0 1 5 1.5v2A1.5 1.5 0 0 1 3.5 5h-2A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-2zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z'/></svg>");
    background-repeat: no-repeat;
    height: 1.2em;
    width: 1.2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .upload-to-cloud {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='red' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z'/><path fill-rule='evenodd' d='M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z'/></svg>");
    background-repeat: no-repeat;
    height: 1.2em;
    width: 1.2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .cloud-warning {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='red' xmlns='http://www.w3.org/2000/svg'><g id='cloud-warning--cloud-network-internet-server-warning-alert'><path id='Vector' stroke='#2859c5' stroke-linecap='round' stroke-linejoin='round' d='M10.179 12.374h1.364a2.925 2.925 0 0 0 0.654 -5.773 0.264 0.264 0 0 1 -0.185 -0.214A4.144 4.144 0 0 0 3.964 5.411a0.243 0.243 0 0 1 -0.225 0.136 3.421 3.421 0 0 0 -2.021 5.988c0.682 0.597 1.495 0.838 2.48 0.838h0.621' stroke-width='1'></path><path id='Vector_2' stroke='#2859c5' stroke-linecap='round' stroke-linejoin='round' d='M7.5 7.665v3.75' stroke-width='1'></path><g id='Group 4488'><path id='Ellipse 81' stroke='#2859c5' stroke-linecap='round' stroke-linejoin='round' d='M7.5 14.196a0.268 0.268 0 1 1 0 -0.536' stroke-width='1'></path><path id='Ellipse 82' stroke='#2859c5' stroke-linecap='round' stroke-linejoin='round' d='M7.5 14.196a0.268 0.268 0 1 0 0 -0.536' stroke-width='1'></path></g></g></svg>");
    background-repeat: no-repeat;
    height: 1.2em;
    width: 1.2em;
    vertical-align: middle;
    overflow: hidden;
    padding-top: 0px !important;
  }

  .item-type-input {
    border: none !important;
    background-color: transparent !important;
  }

  .programme {
    min-width: 300px !important;
    overflow: visible;
  }

  .circle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 2px solid #abc;
    font-size: 14px;
    text-align: center;
    color: #abc;
    padding-top: 2px;
  }

  .circle-sm {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1.5px solid #abc;
    font-size: 9px;
    text-align: center;
    color: #abc;
    padding-top: 1px;
  }

  .text-muted {
    color: #A9A9A9
  }

  .text-error {
    color: #ff1155;
  }

  .font-size-extra-extra-small {
    font-size: 0.75rem;
  }

  .font-size-extra-small {
    font-size: 0.85rem;
  }

  .font-size-small {
    font-size: 0.90rem;
  }

  .font-size-large {
    font-size: 1.5rem;
  }

  .text-lighter {
    font-weight: lighter;
  }

  .text-align-right {
    text-align: right;
  }

  .text-align-center {
    text-align: center;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .hoverable:hover,
  .hoverable:focus-visible,
  .hoverable:focus-within {
    border: 2px solid !important;
    border-radius: 4px !important;
    margin-top: -2px !important;
    margin-left: -2px !important;
  }

  .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    /* was  height: calc(1.5em + 0.1rem + 2px) !important;*/
  }

  